'use client'

import LinkGradient from "@/components/link-button/link-gradient";
import { useKeenSlider } from "keen-slider/react";
import Image from "next/image";
import Link from "next/link";

const BlockEquipment = ({ title = '', products = null, isShowButton = true }) => {
    const [sliderRefEquipment, instanceRef] = useKeenSlider(
        {
            loop: true,
            slides: {
                perView: 4,
                spacing: 10,
            },
            breakpoints: {
                "(max-width: 1200px)": {
                    slides: { perView: 3 }, // 3 slides for widths <= 1200px
                },
                "(max-width: 768px)": {
                    slides: { perView: 2 }, // 2 slides for widths <= 768px
                },
                "(max-width: 480px)": {
                    slides: { perView: 1 }, // 1 slide for widths <= 480px
                },
            }
        },
        [
            (slider) => {
                let timeout
                let mouseOver = false
                function clearNextTimeout() {
                    clearTimeout(timeout)
                }
                function nextTimeout() {
                    clearTimeout(timeout)
                    if (mouseOver) return
                    timeout = setTimeout(() => {
                        slider.next()
                    }, 2000)
                }
                slider.on("created", () => {
                    slider.container.addEventListener("mouseover", () => {
                        mouseOver = true
                        clearNextTimeout()
                    })
                    slider.container.addEventListener("mouseout", () => {
                        mouseOver = false
                        nextTimeout()
                    })
                    nextTimeout()
                })
                slider.on("dragStarted", clearNextTimeout)
                slider.on("animationEnded", nextTimeout)
                slider.on("updated", nextTimeout)
            },
        ]
    )

    const ProductCard = ({ product }) => {
        return (
            <div className="keen-slider__slide">
                <div className="w-full p-2">
                    <Link href={'#'} className="block relative w-[270px] h-[280px] overflow-hidden mx-auto">
                        <Image
                            alt={product.name}
                            className="object-cover object-center w-full h-full block"
                            src={product.image}
                            width={420}
                            height={260}
                        />
                    </Link>
                    <div className="mt-6">
                        <h3 className="text-[#676B77] font-normal text-base line-clamp-1">{product.category}</h3>
                        <Link href={'#'} className="text-[#02091C] font-medium text-lg line-clamp-2 hover:text-[#7C80FE]">{product.name}</Link>
                        <p className="mt-5 text-[#7C80FE] font-bold text-lg">{product.price}</p>
                    </div>
                </div>
            </div>
        );
    };

    const Arrow = (props) => {
        return (
            <svg onClick={props.onClick} width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                {props.left && (<path d="M8.33301 1L1.33301 8L8.33301 15" stroke="#02091C" strokeWidth="1.5" strokeLinecap="round" />)}
                {!props.left && (<path d="M1.66699 15L8.66699 8L1.66699 1" stroke="#02091C" strokeWidth="1.5" strokeLinecap="round" />)}
            </svg>
        )
    }

    return (
        <>

            {products && products.length > 0 && (
                <div className="container mx-auto mt-16 mb-10 md:mb-28">
                    <div className="flex items-center justify-between border-b border-[#E6E9EB] border-solid pb-4 px-4">
                        <div>
                            <p className="text-[#02091C] font-bold text-2xl">{title}</p>
                        </div>
                        <div className="flex items-center justify-center gap-4 h-[52px]">
                            <div className="hidden md:flex items-center justify-center gap-3">
                                <button
                                    className="rounded-full w-9 md:w-[52px] h-9 md:h-[52px] bg-[#F5F6F8] flex items-center justify-center"
                                    onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
                                >
                                    <Arrow left />
                                </button>
                                <button
                                    className="rounded-full w-9 md:w-[52px] h-9 md:h-[52px] bg-[#F5F6F8] flex items-center justify-center"
                                    onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
                                >
                                    <Arrow />
                                </button>
                            </div>
                            {isShowButton && (
                                <>
                                    <div className="hidden md:block w-[1px] bg-[#E6E9EB] h-full"></div>
                                    <LinkGradient title="Tất cả thiết bị" myClass="px-5"/>
                                </>
                            )}
                        </div>
                    </div>
                    <div ref={sliderRefEquipment} className="keen-slider mt-8 md:mt-16">
                        {products.map((product) => (
                            <ProductCard key={product.id} product={product} />
                        ))}
                    </div>
                </div>
            )}
        </>
    )
}
export default BlockEquipment;